import ky from 'ky'
import { NormalizedOptions } from 'ky/distribution/types/options'
import { delToken, getToken } from '@/helpers/apiToken'

export const $http = ky.extend({
  prefixUrl: import.meta.env.VITE_API_URL,
  credentials: 'include',
  hooks: {
    beforeRequest: [
      // Set the API key header
      async (request, _options: NormalizedOptions) => {
        const token = await getToken()
        if (token) request.headers
          .set('Authorization', `Bearer ${await getToken()}`)
      }
    ],
    afterResponse: [
      // Remove the token from storage if the request says we're unauthorized
      async (request: Request, options: NormalizedOptions, response: Response) => {
        if (response.status === 401) {
          console.warn('[HTTP] Unauthenticated response')
          await delToken()
          window.location.replace('/')
        }
      }
    ]
  }
})
