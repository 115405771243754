import { createStore, del, get, set } from 'idb-keyval'

export const key: Readonly<string> = 'DEFY'

const store = createStore('THAR', 'keychain')

/**
 * ## Determines if a token exists
 * @note Does not determine if the token is valid.
 */
export const hasToken = async (): Promise<boolean> => !!(await get(key, store))

/**
 * ## Sets a new token into the persisted storage
 * @param token
 */
export const setToken = async (token: string): Promise<void> => await set(key, token, store)

/**
 * ## Retreives the token from the persisted storage
 */
export const getToken = async (): Promise<string | undefined> => await get(key, store)

/**
 * ## Deletes the token from the storage
 * @param token
 */
export const delToken = async (): Promise<void> => await del(key, store)
