import { createApp } from 'vue'
import { createDefaultSWR } from 'vswr'
import { createPinia } from 'pinia'
import { router } from '@/router'
import { alertController, IonicVue } from '@ionic/vue'
import { default as InstantSearch } from 'vue-instantsearch/vue3/es'
// Components
import App from './App.vue'
// Helpers
import { $http } from '@/http'
// Types
import type { Input, Options } from 'ky/distribution/types/options'
import type { IonicConfig } from '@ionic/core/dist/types/utils/config'
// Stylesheet
import './css/index.css'
// Service worker // PWA stuff
import { registerSW } from 'virtual:pwa-register'
import { i18n } from '@/lang'

const updateSW = registerSW({
  async onNeedRefresh () {
    const prompt = await alertController.create({
      header: 'Software Update',
      message: 'Hoi! Er is een nieuwe versie van de helpdesk app beschikbaar. Wil je nu bijwerken? We hoeven alleen de app even voor je te herladen.',
      buttons: [
        { text: 'Nee', role: 'cancel' },
        { text: 'Werk bij', handler: updateSW }
      ]
    })
    await prompt.present()
  },
  onOfflineReady () {
    console.debug('onOfflineReady called.')
  },
})

// Set the default SWR instance that will
// be used by all the exported APIs.
// https://github.com/ConsoleTVs/vswr#re-validate-on-demand
createDefaultSWR({
  // Configure a global fetcher for all SWR hooks. This can be
  // replaced with anything that returns a promise with the data
  fetcher: (key: Input, options: Options) => $http.get(key, options).json(),
  focusThrottleInterval: 6000,
})

const pinia = createPinia()
const app = createApp(App)
const ionicConfig: IonicConfig = {
  mode: 'ios',
  swipeBackEnabled: true,
  toggleOnOffLabels: true,
  toastDuration: 2416,
}

app
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(IonicVue, ionicConfig)
  .use(InstantSearch)
  .mount('#app')
