import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  locale: 'nl',
  legacy: false,
  availableLocales: ['nl'],
  // something vue-i18n options here ...
  datetimeFormats: {
    nl: {
      shortDate: {
        dateStyle: 'short'
      },
      short: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      shortDateTime: {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      },
      mediumDate: {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      },
      medium: {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      },
      longDate: {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      },
      long: {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
    }
  },
  numberFormats: {
    nl: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'
      },
      currencyNoSymbol: {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      },
      fileSize: {
        style: 'decimal',
        maximumFractionDigits: 1,
        minimumFractionDigits: 0
      }
    }
  },
  messages: {
    nl: {
      'languages': {
        'ISO639_NOTE': 'Language name codes',
        'ISO639': {
          'nl': 'Nederlands',
          'en': 'Engels',
          'pl': 'Pools',
          'bg': 'Bulgaars',
          'fr': 'Frans',
          'de': 'Duitsland'
        },
        'ISO3166_NOTE': 'Country name codes',
        'ISO3166': {
          'NL': 'Nederland',
          'GB': 'Groot Brittanie',
          'DE': 'Duitsland',
          'BE': 'België',
          'PL': 'Polen',
          'BG': 'Bulgarije',
          'FR': 'Frankrijk'
        }
      },
    }
  }
})
