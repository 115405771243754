import { NavigationGuard } from 'vue-router'
import { hasToken } from '@/helpers/apiToken'

const Auth: NavigationGuard = async (
  to,
  from,
) => {
  // If we have no token, force the user to the auth route.
  if (!(await hasToken())) return { name: 'login', force: true, replace: true }
}

export default Auth
