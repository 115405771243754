<template>
  <IonApp>
    <IonRouterOutlet ref="routerOutletRef" />
  </IonApp>
</template>

<script lang="ts" setup>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { provide, ref } from 'vue'

const routerOutletRef = ref(null)
provide('ionRouter', routerOutletRef)
</script>
