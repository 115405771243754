import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { middleware } from '@/router/middleware'

const routes: RouteRecordRaw[] = [
  {
    path: '/', alias: ['/login'],
    component: () => import('@/layouts/CenteredLayout.vue'),
    children: [{
      path: '',
      name: 'login',
      component: () => import('@view/Auth/Login.vue'),
    }]
  },

  {
    path: '/',
    beforeEnter: [middleware.Auth],
    component: () => import('@/layouts/HomeTabbedLayout.vue'),
    children: [
      {
        path: 'home', name: 'home',
        component: () => import('@view/Home/TabHome.vue'),
      },
      {
        path: 'planning', name: 'planning',
        component: () => import('@view/Home/TabHome.vue'),
      },
      {
        path: 'learnings', name: 'learnings',
        component: () => import('@view/Home/TabHome.vue'),
      },
      {
        path: 'search', name: 'search',
        component: () => import('@view/Home/TabSearch.vue'),
      },
      {
        path: 'settings', name: 'settings',
        component: () => import('@view/Settings.vue'),
      },
    ]
  },

  {
    path: '/user/:id',
    component: () => import('@layout/UserTabbedLayout.vue'),
    beforeEnter: [middleware.Auth],
    children: [
      { path: '', name: 'user.home', redirect: { name: 'user.details' } },
      {
        path: 'details', name: 'user.details',
        component: () => import('@view/User/Details.vue'),
      },
      {
        path: 'planning', name: 'user.planning',
        component: () => import('@view/User/Bookings.vue'),
      },
      {
        path: 'courses', name: 'user.courses',
        component: () => import('@view/User/Courses.vue'),
      },
      {
        path: 'learnings', name: 'user.learnings',
        component: () => import('@view/User/Learnings.vue'),
      },
    ]
  },

  // Keep as LAST entry
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('@view/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export { router }
